import PropTypes from 'prop-types';
import Icon from './Icon';

/**
- Based on the Icon Component
- To be used in other components
* */

export default function IcCloseSm({className}) {
  return (
    <Icon className={className} label="Close Item Desktop">
      <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6485 6.35147C17.1799 5.88284 16.4201 5.88284 15.9515 6.35147L6.35147 15.9515C5.88284 16.4201 5.88284 17.1799 6.35147 17.6485C6.8201 18.1172 7.5799 18.1172 8.04853 17.6485L17.6485 8.04853C18.1172 7.5799 18.1172 6.8201 17.6485 6.35147Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.35147 6.35147C6.8201 5.88284 7.5799 5.88284 8.04853 6.35147L17.6485 15.9515C18.1172 16.4201 18.1172 17.1799 17.6485 17.6485C17.1799 18.1172 16.4201 18.1172 15.9515 17.6485L6.35147 8.04853C5.88284 7.5799 5.88284 6.8201 6.35147 6.35147Z"
        />
      </svg>
    </Icon>
  );
}

IcCloseSm.propTypes = {
  className: PropTypes.string,
};
IcCloseSm.defaultProps = {
  className: '',
};
